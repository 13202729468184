.message-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
}

.message-entry-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 25px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.message-entry-container:active {
  background-color: #f5f5f5;
}

.message-entry-snapshot {
  font-size: 1.2vw;
  color: #777777;
  line-clamp: 1;
  overflow: hidden;
}

.message-entry-new {
  color: green;
}
