.form-btn {
  width: 48%;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 10px;
  margin-top: 15px;
  border-style: none;
  border-radius: 10px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.form-btn:hover {
  background-color: #60b89e;
  color: #222;
}

.form-btn:active {
  transform: scale(0.95);
}
