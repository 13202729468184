.sessions-root-container {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  user-select: none;
}

.sessions-list {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-left: 1px solid #e0e0e0;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.sessions-list::-webkit-scrollbar {
  display: none;
}

.sessions-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.sessions-item:active {
  transform: scale(0.97);
}

.sessions-item-number {
  width: 2rem;
  text-align: center;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #424242;
  margin-right: 10px;
}

.sessions-item-cover {
  width: 75px;
  height: 75px;
  object-fit: cover;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.sessions-item-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.sessions-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sessions-item-header-left {
  flex: 1;
}

.sessions-item-header-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #424242;
}

.sessions-item-header-date {
  font-size: 0.8rem;
  font-weight: 500;
  color: #757575;
  margin-top: 2px;
}

.sessions-item-body {
  display: flex;
  flex-direction: row;
}

.sessions-item-body-type {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.sessions-item-body-type-reason {
  font-size: 0.8rem;
  padding: 5px 10px;
  background-color: #333333;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
}

.sessions-item-body-type-item {
  font-size: 0.8rem;
  padding: 5px 10px;
  background-color: lightgray;
  border-radius: 5px;
  margin-right: 5px;
}

.sessions-item-header-effect {
  align-self: flex-start;
  width: 4.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.2rem 0.5rem 0.3rem 0.5rem;
  border-radius: 0.2rem;
}

.sessions-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.sessions-detail-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.sessions-detail-container::-webkit-scrollbar {
  display: none;
}

.sessions-detail-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.sessions-detail-header-cover {
  width: 180px;
  height: 180px;
  object-fit: cover;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.sessions-detail-header-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.sessions-detail-header-weather {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sessions-detail-header-weather-icon {
  height: 2em;
  aspect-ratio: 1;
  margin-right: 5px;
}

.sessions-detail-header-weather-name {
  font-size: 0.9em;
  font-weight: 500;
  color: #757575;
}

.sessions-detail-header-weather-dropdown {
  max-height: 300px;
  width: 90%;
  position: absolute;
  top: 10;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  overflow: auto;
  visibility: hidden;
}

.sessions-detail-header-weather:hover
  + .sessions-detail-header-weather-dropdown {
  visibility: visible;
}

.sessions-detail-header-weather-dropdown:hover {
  visibility: visible;
}

.sessions-detail-header-weather-dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.sessions-detail-header-weather-dropdown-item:last-child {
  border-bottom: none;
}

.sessions-detail-header-weather-dropdown-item-label {
  flex: 1;
  font-size: 0.9em;
  font-weight: 500;
  color: #757575;
}

.sessions-detail-header-weather-dropdown-item-value {
  font-size: 0.9em;
  font-weight: 500;
  color: #424242;
}

.sessions-detail-header-name {
  font-size: 2em;
  font-weight: bold;
  color: #424242;
}

.sessions-detail-header-date {
  font-size: 0.9em;
  font-weight: 500;
  color: #757575;
  margin-top: 3px;
}

.sessions-detail-subheader {
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
  padding: 10px 0px;
  justify-content: space-around;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.sessions-detail-entity {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sessions-detail-entity-icon {
  font-size: 2em;
  font-weight: bold;
  color: #424242;
}

.sessions-detail-entity-name {
  font-size: 1em;
  font-weight: 500;
  color: #757575;
}

.sessions-detail-labels {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
}

.sessions-detail-reason-item {
  font-size: 1em;
  font-weight: 500;
  padding: 5px 10px;
  background-color: #333333;
  color: white;
  border-radius: 5px;
  margin: 0px 5px 5px 0px;
}

.sessions-detail-activities-item {
  font-size: 1em;
  font-weight: 500;
  padding: 5px 10px;
  background-color: lightgray;
  border-radius: 5px;
  margin: 0px 5px 5px 0px;
}

.sessions-detail-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sessions-detail-body-track {
  display: flex;
  flex-direction: row;
  margin: 0px 0px 10px 0px;
}

.sessions-detail-body-track-cover {
  width: 75px;
  height: 75px;
  object-fit: cover;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.sessions-detail-body-track-info {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.sessions-detail-body-track-info-title {
  max-width: 30ch;
  font-size: 1.2em;
  font-weight: bold;
  color: #424242;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sessions-detail-body-track-info-artist {
  max-width: 42ch;
  font-size: 0.9em;
  font-weight: 500;
  color: #757575;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sessions-detail-body-track-info-effect {
  align-self: flex-start;
  width: 3rem;
  text-align: center;
  font-size: 0.8em;
  color: black;
  margin-top: 5px;
  padding: 0.2rem 0.5rem 0.3rem 0.5rem;
  border-radius: 0.2rem;
}
