.download-data-root-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  overflow: auto;
  justify-content: space-between;
}

.download-data-collection-container {
  flex: 0 1 28%;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.download-data-collection-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.download-data-collection-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #424242;
}

.download-data-collection-count {
  font-size: 0.8rem;
  font-weight: bold;
  color: gray;
}

.download-data-collection-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.download-data-collection-btn {
  flex: 0 1 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: white;
  color: #424242;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.download-data-collection-btn-icon {
  font-size: 1.2rem;
  margin-right: 10px;
}

.download-data-collection-btn:hover {
  background-color: black;
  color: white;
}

.download-data-collection-btn:active {
  scale: 0.95;
}
