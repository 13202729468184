.resident-form__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #222;
}

.resident-form__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
  margin-bottom: 15px;
}

.resident-form__instruction {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.resident-form__playlist-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.resident-form__playlist-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #222;
  border: 1px solid #ccc;
}

.resident-form__playlist-remove-btn {
  padding: 10px 10px;
  border-style: none;
  border-radius: 10px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.resident-form__playlist-remove-btn:hover {
  background-color: #60b89e;
  color: #222;
}

.resident-form__playlist-remove-btn:active {
  transform: scale(0.95);
}

.resident-form__playlist-add-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resident-form__playlist-label {
  padding: 10px;
  border-radius: 10px;
  border-color: gray;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  color: gray;
}
