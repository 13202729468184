.user-edit-form__profile {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-bottom: 20px;
}

.user-edit-form__profile__avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.user-edit-form__profile__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}

.user-edit-form__field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.user-edit-form__field__label {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.user-edit-form__field__value {
  flex: 1;
  font-size: 1.1em;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.user-edit-form__profile__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
}

.user-edit-form__profile__button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #222;
  color: #fff;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.user-edit-form__profile__button:hover {
  background-color: #60b89e;
  color: #222;
}

.user-edit-form__profile__button:active {
  transform: scale(0.95);
}
