.form-header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.form-header__pagination {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form-header__add-btn {
  background-color: cadetblue;
  border: none;
  color: #eee;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.form-header__add-btn:active {
  opacity: 0.5;
  transform: translateY(2px);
}

.form-header__prev-btn,
.form-header__next-btn {
  background-color: #fff;
  border: none;
  color: #333;
  padding: 10px 20px;
  margin: 0 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.form-header__prev-btn:active,
.form-header__next-btn:active {
  opacity: 0.5;
  transform: translateY(2px);
}

.form-header__page-btn {
  width: 65px;
  border: none;
  color: #333;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
