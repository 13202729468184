.login-root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.logo-text {
  font-size: 6rem;
  font-weight: 600;
  color: #fff;
  margin-left: 3rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 450px;
}

.login-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff22;
  border-radius: 2rem;
  height: 4rem;
  margin-bottom: 1.5rem;
}

.login-icon {
  font-size: 2rem;
  color: #eee;
  margin-left: 1.5rem;
}

.login-field {
  flex: 1;
  height: 2rem;
  background-color: transparent;
  border: none;
  color: #eee;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  outline: none;
}

.login-field::placeholder {
  color: #aaa;
  font-size: 1.2rem;
}

.login-btn {
  background-color: #60b89e;
  border: none;
  border-radius: 2rem;
  height: 4rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #222;
  margin-top: 1rem;
  margin-bottom: 3rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.login-btn:active {
  transform: scale(0.95);
}

.login-field:-webkit-autofill,
.login-field:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
