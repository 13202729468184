.video-add-form__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #222;
}

.video-add-form__thumbnail-container {
  width: 15vw;
  aspect-ratio: 16/9;
  overflow: hidden;
  margin-bottom: 10px;
}

.video-add-form__row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
}

.video-add-form__row > input {
  flex: 1;
}

.video-add-form__row > button {
  font-weight: bold;
  font-size: 1.1em;
  padding: 8px 30px;
  margin-left: 15px;
  border-style: none;
  border-radius: 10px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.video-add-form__row > button:hover {
  background-color: #60b89e;
  color: #222;
}

.video-add-form__row > button:active {
  transform: scale(0.95);
}

.video-add-form__form label {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.video-add-form__form input {
  font-size: 1.1em;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.video-add-form__profile__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.video-add-form__profile__button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #222;
  color: #fff;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.video-add-form__profile__button:hover {
  background-color: #60b89e;
  color: #222;
}

.video-add-form__profile__button:active {
  transform: scale(0.95);
}
