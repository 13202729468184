.overview-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.count-card-container {
  height: 25%;
  min-height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 10px;
}

.overview-chart-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0px 20px 20px 20px;
}

.overview-chart-pie-panel {
  width: 25%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  padding: 20px;
}

.overview-chart-bar-panel {
  flex: 1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.overview-chart-pie-overall {
  width: 100%;
  flex: 1.5;
}

.overview-chart-pie-subcharts {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overview-chart-pie-subchart {
  width: 48%;
}
