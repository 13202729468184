.subpage-error-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.subpage-error-icon {
  font-size: 100px;
  color: #92a6bd;
  margin-bottom: 10px;
}

.subpage-error-title {
  font-size: 30px;
  color: #92a6bd;
  margin-bottom: 10px;
}

.subpage-error-message {
  font-size: 20px;
  color: #92a6bd;
}
