.video-stats__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.video-stats__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.video-stats__block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.video-stats__path {
  font-size: 1vw;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: black;
  color: white;
}

.video-stats__name {
  font-size: 1.5vw;
  font-weight: bold;
  color: #333;
  margin-left: 15px;
}

.video-stats__chart-container {
  flex: 1;
  padding: 20px;
  border: 1px solid #e0e0e0;
}

.video-stats__info-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.video-stats__stream-container {
  width: 35%;
  margin-right: 20px;
}

.video-stats__stream {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  border: none;
}

.video-stats__subtitle-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.video-stats__subtitle {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  font-size: 15px;
  line-height: 20px;
  resize: none;
}

.video-stats__update-button {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 20px;
  border-radius: 25px;
  background-color: black;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.video-stats__link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1vw;
  font-weight: bold;
  padding: 10px;
  margin-left: 15px;
  border-radius: 10px;
  color: #444444;
  border: 1px solid #444444;
  align-self: center;
  cursor: pointer;
  transition: all 0.3s;
}

.video-stats__link-container:hover {
  background-color: black;
  color: white;
}