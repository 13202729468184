.user-add-form__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #222;
}

.user-add-form__form label {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.user-add-form__form input {
  font-size: 1.1em;
  padding: 6px 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.user-add-form__select {
  margin-bottom: 15px;
}

.user-add-form__btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
