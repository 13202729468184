.widgets-input {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.widgets-input label {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.widgets-input input {
  font-size: 1.1em;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 6px 10px;
  margin-bottom: 15px;
}

.widgets-input-error {
  color: red;
  font-size: 1em;
  margin-bottom: 15px;
}

.widgets-input-checklist {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.widgets-input-checklist-prompt {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.widgets-input-checklist-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  font-size: large;
  font-weight: normal;
}

.widgets-input-checklist-item input {
  margin-right: 10px;
}

.widgets-form-btn {
  flex: 1;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 10px;
  margin-top: 15px;
  border-style: none;
  border-radius: 10px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.widgets-form-btn:hover {
  background-color: #60b89e;
  color: #222;
}

.widgets-form-btn:active {
  transform: scale(0.95);
}

.widgets-primary-btn {
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 30px;
  border-style: none;
  border-radius: 10px;
  background-color: #60b89e;
  color: #222;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.widgets-primary-btn:active {
  transform: scale(0.95);
}
