.static-content-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
}

.static-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.static-content-info {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 2px solid #eeeeee;
}

.static-content-index {
  width: 2ch;
  margin-right: 20px;
  font-size: 1.2rem;
  color: #444444;
  line-height: 1.5;
}

.static-content-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #444444;
  line-height: 1.5;
}

.static-content-module {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  font-weight: normal;
  font-size: 1rem;
  color: gray;
  border: 1px solid lightgray;
  padding: 5px 10px;
}

.static-content-content {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  color: #444444;
  line-height: 1.5;
  text-align: justify;
}

.static-content-separator {
  margin: 10px 0px;
}

.static-content-icon {
  margin-right: 10px;
  margin-top: 4px;
}

.static-content-text {
  flex: 1;
}

.static-content-actions {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  justify-content: flex-end;
}

.static-content-action {
  font-size: 1.2rem;
  border: 1px solid #444444;
  padding: 5px 50px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.static-content-action:hover {
  background-color: #444444;
  color: white;
}

.static-content-modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #444444;
  margin-bottom: 20px;
}

.static-content-modal-label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #444444;
  margin: 10px 0px;
}

.static-content-modal-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.static-content-modal-input {
  font-size: 1rem;
  padding: 10px 20px;
  border: 1px solid #444444;
  border-radius: 5px;
  margin-bottom: 10px;
}

.static-content-modal-content {
  flex: 1;
  padding: 10px 20px;
  font-size: 1rem;
  color: #444444;
  line-height: 1.5;
}

.static-content-modal-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.static-content-modal-action {
  font-size: 1.2rem;
  font-weight: bold;
  width: 48%;
  border-style: none;
  background-color: #444444;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.static-content-modal-action:active {
  scale: 0.95;
}

.static-content-add-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.static-content-add-button {
  background-color: cadetblue;
  border: none;
  color: #eee;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.static-content-add-button:active {
  opacity: 0.5;
  transform: translateY(2px);
}
