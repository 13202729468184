.subpage-loading-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.subpage-loading-title {
  font-size: 30px;
  color: #92a6bd;
  margin-bottom: 10px;
}
