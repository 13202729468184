.tracklist-root-container {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.tracklist-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

.tracklist-container::-webkit-scrollbar {
  display: none;
}

.tracklist-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.tracklist-details-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  user-select: none;
}

.tracklist-details-container::-webkit-scrollbar {
  display: none;
}

.tracklist-track {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.1s ease-in-out;
  user-select: none;
}

.tracklist-track:active {
  scale: 0.97;
}

.tracklist-track__index {
  width: 2rem;
  text-align: center;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #424242;
  margin-right: 10px;
}

.tracklist-track__cover {
  width: 75px;
  height: 75px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.tracklist-track__name {
  max-width: 75ch;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tracklist-track__artist {
  max-width: 80ch;
  font-size: 1em;
  font-weight: 500;
  color: #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tracklist-details-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.tracklist-details-cover {
  width: 150px;
  height: 150px;
  margin-right: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.tracklist-details-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.tracklist-details-name {
  max-width: 25ch;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tracklist-details-artist {
  max-width: 33ch;
  font-size: 0.9em;
  font-weight: 500;
  color: #757575;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tracklist-details-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;
  font-weight: 500;
  color: #333;
  margin-top: 5px;
}

.tracklist-details-field__text {
  max-width: 30ch;
  margin-left: 1ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 1;
}

.tracklist-details-effect {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  font-size: 0.9em;
  font-weight: 500;
}

.tracklist-details-effect-positive {
  width: 4ch;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  text-align: center;
  background-color: #4caf50;
}

.tracklist-details-effect-neutral {
  width: 4ch;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  background-color: #ffeb3b;
}

.tracklist-details-effect-negative {
  width: 4ch;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 5px;
  text-align: center;
  background-color: #f44336;
}

.tracklist-details-effects {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  color: #333333;
}

.tracklist-details-effect-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #e0e0e0;
}
