.filter-header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.filter-header__filters {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
}

.filter-header__field {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  align-items: center;
}

.filter-header__field-label {
  margin-right: 0.5rem;
}

.filter-header__select {
  flex: 1;
  max-width: 20rem;
}

.filter-header__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
}

.filter-header__button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
}
