.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.section__info {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 1rem;
}

.section__list {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 1rem;
  margin-top: 0;
}

.new-user-chart {
  flex: 3;
  margin-right: 0.5rem;
  background-color: white;
  height: 40vh;
  padding: 2vh;
  margin: 0.5vw 0px;
  margin-right: 0.5vw;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.user-mgmt-btns {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-stats-chart {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2vh;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.user-stats-chart td,
.user-stats-chart th {
  padding: 0.5vh;
}

.user-stats-chart th {
  font-size: 1.5vh;
}

.user-stats-chart td {
  font-size: 1.5vh;
  border-bottom: 1px solid #ddd;
}

.user-table {
  height: 100%;
  overflow: auto;
}

.user-genre-stats {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.user-genre-stats__col {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user-genre-stats__title {
  font-size: 1.5vh;
  font-weight: bold;
  margin-bottom: 2vh;
  text-align: center;
}

.user-genre-stats__list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 1vw;
  overflow: auto;
}

.user-genre-stats__item {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.user-genre-stats__item__name {
  flex: 1;
  font-size: 1.5vh;
}

.user-genre-stats__item__count {
  flex: 1;
  font-size: 1.5vh;
  text-align: right;
}
