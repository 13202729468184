.user-mgmt-btn {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5vw;
  margin-right: 0;
  padding: 0 1vw;
  padding-right: 0;
  background-color: white;
  border-color: #999;
  border-style: none;
  border-radius: 0.5vw;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.user-mgmt-btn:active {
  transform: scale(0.95);
}

.user-mgmt-btn__title {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-mgmt-btn__icon {
  padding-top: 0.5vw;
  font-size: 2vw;
  color: #333;
  margin-right: 0.75vw;
}

.user-mgmt-btn__text {
  font-size: 1.5vw;
  font-weight: 600;
  color: #333;
}

.user-mgmt-btn__arrow {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1vw;
  font-size: 2vw;
  color: #333;
}
