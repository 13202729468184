.nav-bar {
  background-color: #3d4d5a;
  width: 250px;
  display: flex;
  flex-direction: column;
}

.nav-bar__logo {
  margin: 20px;
  padding-bottom: 20px;
  border-color: #4d5f6e;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.nav-logo-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.nav-logo-text {
  margin-left: 15px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-bar__links {
  display: flex;
  flex-direction: column;
}

.nav-link {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none;
  padding: 15px 20px;
}

.nav-link:hover {
  background-color: #4d5f6e;
}

.nav-link.active {
  background-color: #4ba9c3;
}

.nav-link-text {
  font-size: 1.1rem;
  margin-left: 15px;
}

.nav-bar__logout {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 2.5rem;
}

.logout-btn {
  background-color: #60b89e;
  color: #222;
  display: flex;
  flex-direction: row;
  border: none;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 15px 40px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.logout-btn:active {
  transform: scale(0.95);
}

.logout-btn-text {
  font-size: 1.2rem;
  margin-left: 15px;
}
