.chat-form-root-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chat-form-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.chat-form-header-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
}

.chat-form-header-close {
  color: black;
  cursor: pointer;
}

.chat-form-thread-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1rem;
}

.chat-form-thread-message-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.chat-form-thread-message-container-you {
  justify-content: flex-end;
}

.chat-form-thread-message-image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
}

.chat-form-thread-message-text {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.chat-form-thread-message-text-name {
  font-size: 1rem;
  font-weight: 500;
  color: gray;
  margin-bottom: 0.25rem;
}

.chat-form-thread-message-text-name-you {
  text-align: right;
}

.chat-form-thread-message-text-content {
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: #e0e0e0;
}

.chat-form-input-container {
  display: flex;
  flex-direction: row;
}

.chat-form-input {
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #e0e0e0;
  outline: none;
  font-size: 1rem;
}

.chat-form-send-button {
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  background-color: #222;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.chat-form-send-button:hover {
  background-color: #60b89e;
  color: #222;
}

.chat-form-send-button:active {
  transform: scale(0.95);
}
