.learn-module__root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
}

.learn-module__module-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.learn-module__module-cover {
  width: 30vw;
  height: 38vh;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
}

.learn-module__module-info {
  width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.learn-module__module-name {
  flex: 1;
  font-size: 1.6vw;
  font-weight: bold;
  color: #444444;
  margin-bottom: 10px;
}

.learn-module__module-desc {
  font-size: 1vw;
  color: #444444;
  margin-bottom: 10px;
}

.learn-module__module-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.learn-module__video-gallery {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  border-radius: 10px;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.learn-module__video-gallery__item {
  width: 9vw;
  min-width: 9vw;
  margin-right: 15px;
  cursor: pointer;
}

.learn-module__video-gallery__item__thumbnail {
  width: 100%;
  aspect-ratio: 16/9;
}

.learn-module__video-gallery__item__name {
  font-size: 0.8vw;
  color: #444444;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.learn-module__video-gallery__item__thumbnail__add {
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.learn-module__module-checklist {
  display: flex;
  flex-direction: row;
}

.learn-module__module-checklist__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1vw;
  border-color: #444444;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
}

.learn-module-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.learn-module-button {
  width: 32%;
  background-color: white;
  color: #444444;
  padding: 20px 0px;
  border-style: none;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
}

.learn-module-button:active {
  transform: scale(0.95);
}

.learn-module-button-icon {
  font-size: 1.8vw;
  margin-right: 20px;
  margin-top: 5px;
}
