.organization-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

.organization-list__field {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}

.organization-list__field__index {
  width: 50px;
  font-size: 1em;
  font-weight: 700;
  color: #333;
}

.organization-list__field__item {
  flex: 1;
  font-size: 1em;
  font-weight: 700;
  color: #333;
}

.organization-list__field__actions {
  width: 90px;
  font-size: 1em;
  font-weight: 700;
  color: #333;
}

.organization-list__org {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.organization-list__org__index {
  width: 50px;
  font-size: 1.2em;
  color: gray;
  text-align: left;
}

.organization-list__org__name {
  flex: 1;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  text-overflow: ellipsis;
}

.organization-list__org__details {
  flex: 1;
  font-size: 1em;
  color: gray;
  text-overflow: ellipsis;
}

.organization-list__org__actions {
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.organization-list__org__action {
  width: 40px;
  height: 40px;
}
