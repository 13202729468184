.count-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.count-card__content {
  flex: 1;
}

.count-card__icon {
  position: absolute;
  font-size: 7rem;
  align-self: center;
  opacity: 0.1;
}

.count-card__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.count-card__count {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 5px;
  color: #0e213c;
}

.count-card__title {
  font-size: 1.2rem;
  font-weight: 400;
  color: #0e213c;
}

.count-card__colorbar {
  height: 10px;
}
