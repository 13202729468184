.subpage-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.subpage-header__title {
  font-size: 1.7rem;
  font-weight: bold;
  color: #222;
  margin-left: 2rem;
}

.subpage-header__user {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
}

.subpage-header__user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}

.subpage-header__user-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #222;
  margin-right: 2rem;
}

.subpage-header__user-role {
  font-size: 1.2rem;
  font-weight: 300;
  color: #aaa;
  padding: 0.3rem 0.5rem;
  border-color: #aaa;
  border-style: solid;
  border-width: 1px;
  border-radius: 1rem;
}
