.resident-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

.resident-list__resident {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.resident-list__resident__index {
  width: 50px;
  font-size: 1.2em;
  color: gray;
  text-align: left;
}

.resident-list__resident__name {
  flex: 1.5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.resident-list__resident__name__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}

.resident-list__resident__name__text {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  text-overflow: ellipsis;
}

.resident-list__resident__details {
  flex: 1;
  font-size: 1em;
  color: gray;
  text-overflow: ellipsis;
}

.resident-list__resident__actions {
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.resident-list__resident__action {
  width: 40px;
  height: 40px;
}

.resident-list__field {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}

.resident-list__field__index {
  width: 50px;
  font-size: 1em;
  font-weight: 700;
  color: #333;
}

.resident-list__field__item {
  flex: 1;
  font-size: 1em;
  font-weight: 700;
  color: #333;
}

.resident-list__field__action {
  width: 90px;
  font-size: 1em;
  font-weight: 700;
  color: #333;
}
