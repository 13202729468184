.proj-add-form__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #222;
}

.proj-add-form__form > label {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.proj-add-form__form > input {
  font-size: 1.1em;
  padding: 6px 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.proj-add-form__submit-btn {
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 10px;
  margin-top: 15px;
  border-style: none;
  border-radius: 10px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.proj-add-form__submit-btn:hover {
  background-color: #60b89e;
  color: #222;
}

.proj-add-form__submit-btn:active {
  transform: scale(0.95);
}
